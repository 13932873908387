import React from "react";
import classes from "./styles/404.module.css";

const ErrorPage = () => {
  return (
    <div className={classes.ErrorPage}>
      <h1>Oops! Something went wrong :(</h1>
      <a className={classes.returnButton} href="/">Click to return to main page</a>
    </div>
  );

}

export default ErrorPage;